import React, { FC, useEffect, useId, useState } from 'react'
import styles from './Img.module.scss'
import cx from 'classnames'
import { A } from 'components/basic/A'
import { Url } from 'url'
import { NextLinkHref } from 'constants/types'

export interface Source {
	src: string
	mediaQuery: string
	dprHeight?: number
}

export interface ImgProps {
	src: string
	alt: string

	/** An optional array of image sources (used mobile vs. desktop images, etc.) */
	sources?: Source[]

	/** Optional parameter to eager vs lazy-load the image. Lazy load is default.
	 * This should be changed to eager? at the earliest opportunity. */
	lazy?: boolean

	className?: string
	id?: string
	onClick?: () => void

	/** The estimated max-height of this image.
	 * This is used for DPR calculations, and while it is optional, it is highly recommended for proper image handling.
	 * This will default to 1000 if left unset. */
	dprHeight?: number

	/**
	 * Discuss whether this is necessary
	 */
	simple?: boolean

	href?: string | Url | NextLinkHref

	objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' | 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset'

	/**
	 * Rarely used height prop kept for backwards compatibility
	 */
	height?: number

	unoptimized?: boolean

	doNotCompress?: boolean
}

const S3CDN = 'eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets'
const IMGIX_CDN = 'eightsleep.imgix.net'
const BLUR_AMOUNT = 2000

const addImgixParameters = (src: string, doNotCompress: boolean) => {
	let newSrc = src + `?auto=format`

	if (!doNotCompress) {
		newSrc = newSrc + `,compress`
	}

	return newSrc
}

export const srcToImgix = (src: string, doNotCompress: boolean): string => {
	let cutSrc = src.split('?v')[0] // Remove version from shopify url

	if (cutSrc.includes('.svg')) {
		return cutSrc
	}

	if (src.includes(S3CDN)) {
		cutSrc = cutSrc.replace(S3CDN, IMGIX_CDN)
	}

	if (cutSrc.includes('imgix.net')) {
		return addImgixParameters(cutSrc, doNotCompress)
	}
	return cutSrc
}

const DEVICE_PIXEL_RATIOS = [1, 2, 3, 4]
const DEFAULT_DPR_HEIGHT = 300

/**
 * Generates a srcSet string for a <source> or <img> where the input src is converted into an imgix
 * link, and appended with url params for each dpr ratio and the dpr breakpoint height.
 * @param src Input image url. If this isn't an 8S Shopify url, the function will return an empty srcSet.
 * @param dprHeight DPR height breakpoint for the image.
 * @param doNotCompress Flag to disable imgix compression
 */
function getSrcSet(src: string, dprHeight: number | undefined, doNotCompress: boolean) {
	if (src.includes('.svg') || (!src.includes(S3CDN) && !src.includes(IMGIX_CDN))) return ''

	const sources = []

	for (const dpr of DEVICE_PIXEL_RATIOS) {
		sources.push(srcToImgix(src, doNotCompress) + `&h=${dprHeight}&dpr=${dpr} ${dpr}x`)
	}

	return sources.join(',\n')
}

/**
 * Simple responsive image component. Handles Imgix conversion and handles multiple DPRs.
 */
export const Img: FC<ImgProps> = (props) => {
	const [isMounted, setIsMounted] = useState(false)
	const id = useId()

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsMounted(true)
		}
	}, [])

	const windowHeight = isMounted ? Math.max(1000, Math.round(window.innerHeight * 0.8)) : DEFAULT_DPR_HEIGHT

	const generateSources = () => {
		if (!props.sources || !props.sources.length) return null

		return props.sources.map((source) => (
			<source
				key={source.mediaQuery + props.src}
				media={source.mediaQuery}
				srcSet={props.unoptimized ? source.src : getSrcSet(source.src, source.dprHeight || windowHeight, props.doNotCompress)}
			/>
		))
	}

	const renderSimpleImg = () => {
		const loadingType = props.lazy === undefined || props.lazy ? 'lazy' : 'eager'
		return (
			// eslint-disable-next-line @next/next/no-img-element
			<img
				className={cx(styles.img, { [props.className as string]: props.simple })}
				src={srcToImgix(props.src, props.doNotCompress)}
				alt={props.alt}
				loading={loadingType}
				id={props.id}
				srcSet={props.unoptimized ? props.src : getSrcSet(props.src, props.dprHeight || windowHeight, props.doNotCompress)}
				style={{ objectFit: props.objectFit || 'contain' }}
			/>
		)
	}

	if (props.simple) {
		return renderSimpleImg()
	}

	const _id = `img-link-for-${id}`

	return (
		<picture
			onClick={props.onClick}
			className={props.className}
		>
			{generateSources()}
			{props.href ? (
				<A
					href={props.href}
					id={_id}
				>
					{renderSimpleImg()}
				</A>
			) : (
				renderSimpleImg()
			)}
		</picture>
	)
}
